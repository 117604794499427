jQuery(document).ready(function($) {

	//--------------------------------------------
	// Menus
	//--------------------------------------------

	$('#mobile-nav nav > ul').superfish({

		animation: {opacity:'show', height:'show'},
		animationOut: {opacity:'hide', height:'hide'},
		autoArrows: false

	});

	$('#header nav > ul').superfish({

		autoArrows: false

	});

	$('#mobile-nav nav a').click(function(){

		$('#wrapper').removeClass('mobile-nav-open');

	});

	$('.toggle-mobile-nav').click(function(){

		$('#wrapper').toggleClass('mobile-nav-open');

	});

	$('#mobile-nav .close').click(function(){

		$('#wrapper').removeClass('mobile-nav-open');

	});

	$(window).on('orientationchange resize', function() {

		if ($(this).width() >= 960) {

			$('#wrapper').removeClass('mobile-nav-open');

		}

	});

	$('.main-menu a[href="#"]').click(function(){

		return false;

	});

	//--------------------------------------------
	// Forms
	//--------------------------------------------

	$('#contact-form').ajaxForm({

		target: '#contact-form-messages',
		beforeSubmit: function() {
			$('#contact_submit').html('<span class="fa fa-refresh fa-spin"></span>');
		},
		success: function() {
			$('#contact_submit').html('Submit');
		}

	});

	//--------------------------------------------
	// fitVids
	//--------------------------------------------

	$('#demo').fitVids();

	//--------------------------------------------
	// Lightbox
	//--------------------------------------------

	$('.lightbox-image').magnificPopup({
		closeBtnInside: false,
		mainClass: 'rpjc-magnific',
		closeMarkup: '<div title="%title%" class="mfp-close"><span></span><span></span></div>',
		type: 'image'
	});

	$('.gallery-item').magnificPopup({
		delegate: 'a',
		closeBtnInside: false,
		mainClass: 'rpjc-magnific',
		closeMarkup: '<div title="%title%" class="mfp-close"><span></span><span></span></div>',
		type: 'image',
		gallery: {
			enabled: true,
			arrowMarkup: '<div title="%title%" class="mfp-arrow mfp-arrow-%dir%"><span></span><span></span></div>'
		}
	});

	//--------------------------------------------
	// Accordion
	//--------------------------------------------

	$('.accordion .accordion-content').hide();

	$('.accordion-handle').click(function(){

		var accordion = $(this).parent().parent();

		if ($('.accordion-content').not(':animated')) {

			if ($(this).parent().hasClass('selected')) {

				$(this).next().slideUp(300).end().parent().removeClass('selected');

			} else {

				$(accordion).find('.accordion-item').removeClass('selected');
				$(this).parent().addClass('selected');
				$(accordion).find('.accordion-content').slideUp(300);
				$(this).next().slideDown(300);

			}

		}

		return false;

	});

	//--------------------------------------------
	// Tabs
	//--------------------------------------------

	$('.tabbed-content .tab-nav li:first-child').addClass('active');
	$('.tabbed-content .tab-window .tab-panel').not(':first-child').hide();

	$('.tabbed-content .tab-nav a').click(function(){

		var target = $(this).attr('href');

		$(this).parent().addClass('active').siblings().removeClass('active');

		$(target).show().siblings().hide();

		return false;

	});

	//--------------------------------------------
	// Scrolling
	//--------------------------------------------

	// Scroll to element

	$('.scroll-to').click(function(){

		var selected = $(this).attr('href');

		$('html, body').animate({scrollTop: $(selected).offset().top},'slow');

        return false;

	});

   // Scroll to top link

    $('a[href="#top"]').click(function(){

        $('html, body').animate({scrollTop:0}, 'slow');

        return false;

    });

    //--------------------------------------------
	// Responsive Horizontal Scrolling
	//--------------------------------------------

	$( '.responsive-scroll' ).each( function() {
		
		var element       = $( this ),
			scrollWrapper = $( '<div />', {

				'class' : 'scrollable',
				'html'  : '<div class="scrollable-inner" />'

			} ).insertBefore( element );

		element.data( 'scrollWrapper', scrollWrapper );
		element.appendTo( scrollWrapper.find( 'div' ) );

		if ( element.outerWidth() > element.parent().outerWidth() ) {
			
			element.data( 'scrollWrapper' ).addClass( 'has-scroll' );
		
		}

		$( window ).on( 'resize orientationchange', function() {
		
			if ( element.outerWidth() > element.parent().outerWidth() ) {

				element.data( 'scrollWrapper' ).addClass( 'has-scroll' );

			} else {
		
				element.data( 'scrollWrapper' ).removeClass( 'has-scroll' );
		
			}

		} );

	} );

	//--------------------------------------------
	// Add placeholder support for older browsers
	//--------------------------------------------

	if(!Modernizr.input.placeholder){

		$('[placeholder]').focus(function() {

			var input = $(this);

			if (input.val() === input.attr('placeholder')) {
				input.val('');
				input.removeClass('placeholder');
			}

		}).blur(function() {

			var input = $(this);

			if (input.val() === '' || input.val() === input.attr('placeholder')) {

				input.addClass('placeholder');
				input.val(input.attr('placeholder'));

			}

		}).blur();

		$('[placeholder]').parents('form').submit(function() {

			$(this).find('[placeholder]').each(function() {

				var input = $(this);

				if (input.val() === input.attr('placeholder')) {

					input.val('');

				}

			});

		});

	}

});